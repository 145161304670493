import { writeLog } from "./utils";
import { Alert } from "./alerts";
import * as audio from "./audio";
import moment from "moment";
import _ from "lodash";
import fitty from "fitty";
import { Departure, DeparturesResponse } from "./types";
import { CLOSED_STATIONS } from "../api/constants/index";

const closedStations = CLOSED_STATIONS as number[];
const parseStopName = (stopName: string) => stopName ? stopName.replace(/( NB| SB| EB| WB| BRT)/gm, "") : "";
const openScreen = document.querySelector<HTMLElement>(".open-screen");
const closedScreen = document.querySelector<HTMLElement>(".closed-screen");
const closedScreenText = document.querySelector<HTMLElement>(".closed-screen__title");
const connectionLostScreen = document.querySelector<HTMLElement>(".connection-lost-screen");
const stopTitleElement = document.querySelector<HTMLElement>("#stop-title");
const alertCard = document.querySelector<HTMLElement>(".alert-notice");
const alertCardText = document.querySelector<HTMLElement>(
  ".alert-notice__text"
);

function toggleAlert(alert: Alert | null) {
  alertCard.classList.toggle("hide", alert == null);
  alertCardText.innerText = alert && alert.Message;
}

function hideCard(card: HTMLElement) {
  if (!card.classList.contains('hide')) {
    card.classList.add('hide');
  }
}

function showCard(card: HTMLElement) {
  if (card.classList.contains('hide')) {
    card.classList.remove('hide');
  }
}

function updateCard(
  card: HTMLElement,
  departure: Departure,
  isLastDeparture: boolean
) {
  const infoElement = card.querySelector<HTMLElement>(".arrival-card__info");
  const timeElement = card.querySelector<HTMLElement>(".arrival-card__time");
  const locationElement = card.querySelector<HTMLElement>(
    ".arrival-card__location-text"
  );
  const busElement = card.querySelector<HTMLElement>(".arrival-card__bus");
  const routeIdElement = card.querySelector<HTMLElement>(".arrival-card__route_short");
  const isArrivingNow = departure.MinutesUntilArrival === 0;
  infoElement.classList.toggle("hide", departure.LineDescription == null);

  timeElement.innerHTML = isArrivingNow
    ? "Now"
    : `${departure.MinutesUntilArrival}<small>min</small>`;

  const locationTextWasChanged = locationElement.innerText !== departure.DisplayText;
  locationElement.innerText = departure.DisplayText;

  // Meijer
  if (locationTextWasChanged) {
    fitty(".arrival-card__location-text", {
      maxSize: 150
    });
  }

  fitty(".alert-notice__text");

  busElement.innerText = departure.BusNumber;
  routeIdElement.innerText = departure.RouteId;

  // Redline end up here, fixing up possible Bus w/o a bus number
  card.style.setProperty("--line-color", `#${departure.LineColor}`);
  card.classList.toggle("arrival-card--last", isLastDeparture);
  card.classList.toggle("arrival-card--now", isArrivingNow);
}

const connectionsLostCounts = {}

const logConnectionLost = (stopId: string) => {
  const stopFailures = connectionsLostCounts[stopId];

  connectionsLostCounts[stopId] = typeof stopFailures !== 'undefined' ? stopFailures + 1 : 1;

  postLog(`ERROR | ${stopId} - Connection lost: ${connectionsLostCounts[stopId]} time(s)`);
}

const superStopInFinalDepartureWindow = (departure: Departure, isSunday: boolean) => {
  const momentFormat = 'hh:mm:ss';
  const busDepartureTime = moment().add(departure.MinutesUntilArrival, 'minutes');

  const firstWindowStart = moment('23:45:00', momentFormat);
  const firstWindowEnd = moment('01:00:00', momentFormat);

  let isInFinalDepartureWindow =
      busDepartureTime.isBetween(firstWindowStart, firstWindowEnd);

  // Sunday is the only day with different final route times.
  // However, we have to handle this carefully, because the last
  // Saturday bus runs 8:30 - 9:30 PM, which is technically on
  // Sunday morning, not Saturday, so for Sunday we have to
  // handle two different final departure windows
  if (isSunday) {
    const secondWindowStart = moment('20:10:00', momentFormat);
    const secondWindowEnd = moment('21:30:00', momentFormat);
    isInFinalDepartureWindow =
      busDepartureTime.isBetween(firstWindowStart, firstWindowEnd)
        || busDepartureTime.isBetween(secondWindowStart, secondWindowEnd);
  }

  return isInFinalDepartureWindow;
}

const inFinalDepartureWindow = (departure: Departure, isSunday: boolean) => {
  const momentFormat = 'hh:mm:ss';
  const busDepartureTime = moment().add(departure.MinutesUntilArrival, 'minutes');

  const firstWindowStart = moment('00:30:00', momentFormat);
  const firstWindowEnd = moment('01:30:00', momentFormat);

  let isInFinalDepartureWindow =
      busDepartureTime.isBetween(firstWindowStart, firstWindowEnd);

  // Sunday is the only day with different final route times.
  // However, we have to handle this carefully, because the last
  // Saturday bus runs 12:30 - 1:30 AM, which is technically on
  // Sunday morning, not Saturday, so for Sunday we have to
  // handle two different final departure windows
  if (isSunday) {
    const secondWindowStart = moment('21:30:00', momentFormat);
    const secondWindowEnd = moment('22:30:00', momentFormat);
    isInFinalDepartureWindow =
      busDepartureTime.isBetween(firstWindowStart, firstWindowEnd)
        || busDepartureTime.isBetween(secondWindowStart, secondWindowEnd);
  }

  return isInFinalDepartureWindow;
}

export async function refresh(stopId: string) {
  writeLog("Refreshing arrivals");
  const { stopInfo, stopDepartures, highestPriorityAlert, connectionSuccess, isSuperStop } = await fetchData(
    stopId
  );

  if (!connectionSuccess) {
    // logConnectionLost(stopId);

    openScreen.classList.toggle("hide", true);
    closedScreen.classList.toggle("hide", true);
    connectionLostScreen.classList.toggle("hide", false);

    return;
  }

  connectionsLostCounts[stopId] = 0;

  const isClosed = closedStations.includes(parseInt(stopId)) ||
    stopDepartures.length === 0

  audio.setIsRedLine(!isSuperStop);
  audio.setClosed(isClosed);
  audio.setVolume(stopInfo.SpeakerVolume);

  document.title =
    stopInfo.Name != null ? `IndyGo | ${stopInfo.Name}` : "IndyGo";
  stopTitleElement.innerText = parseStopName(stopInfo.Name);

  openScreen.classList.toggle("hide", isClosed);
  closedScreen.classList.toggle("hide", !isClosed);
  connectionLostScreen.classList.toggle("hide", true);

  if (closedStations.includes(parseInt(stopId, 10))) {
    if (closedScreenText) {
      closedScreenText.innerHTML = closedScreenText.innerHTML.replace('for the Evening', '')
    }
    return;
  }

  toggleAlert(highestPriorityAlert);

  stopTitleElement.innerText = parseStopName(stopInfo.Name);

  for (const departure of stopDepartures) {
    if (departure.MinutesUntilArrival === 1) {
      audio.busArriving(departure.BusNumber, departure.DisplayText, departure.IsNorthbound);
    }
  }

  const arrivalCards = document.querySelectorAll<HTMLElement>(".arrival-card");

  const numDepartures = stopDepartures.length;
  const isSunday = moment().isoWeekday() == 7;

  arrivalCards.forEach((card, index) => {
    const departure = stopDepartures[index];

    // Might not have a departure for every card if fewer than four
    // buses are scheduled to arrive in the near future
    if (departure) {
      showCard(card);

      // Two different logic checks to determine if final departure should display:
      // First, has the API given us back fewer than four departures? By default it
      // should always give us the next four unless fewer than four remain, which should
      // only happen at the end of the day when no new buses are being scheduled.
      // HOWEVER, occasionally, if the 4th scheduled departure is still quite far out,
      // the API doesn't return it to us, so we also need to check the second condition,
      // which is the day of the week and time, because the final departure always runs
      // at a fixed time.
      const isLastDepartureFromApi =
        numDepartures < 4 && index === numDepartures - 1;

      const isInFinalDepartureWindow = isSuperStop ?
        superStopInFinalDepartureWindow(departure, isSunday) :
        inFinalDepartureWindow(departure, isSunday);

      // If we are no longer getting results from the API, AND
      // we are within the final departure window, we can display
      // the final departure notification
      var isLastDeparture = isLastDepartureFromApi && isInFinalDepartureWindow;

      card.classList.toggle("arrival-card--hide", departure == null);
      if (departure == null) {
        return;
      }
      // NOTE - if they ever decide to have differences in the card types,
      // we will have to have an `updateCard` and `updateSuperCard` as before,
      // that applies different hide/show rules depending on where we are
      updateCard(card, departure, isLastDeparture);
    } else {
      hideCard(card);
    }
  });
}

function postLog(str: string) {
  fetch('/writeLog', {
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({msg: str})
  });

  writeLog(str);
}

async function fetchData(stopId: string): Promise<DeparturesResponse> {
  try {
    const response = await fetch(`/stop/${stopId}/RefreshDepartures?t=${Date.now()}`);
    const data: DeparturesResponse = await response.json();

    if (parseInt(stopId) === 70001) {
      data.stopInfo = {
        BroadcastMessage: "",
        Description: "66th Station",
        Name: "66th Station",
        SpeakerVolume: 100,
        StopId: 70001
      }
    }

    if (data.availApiError != null) {
      throw new Error(`Avail API error: ${data.availApiError}`);
    }

    localStorage.setItem(
      "SpeakerVolume",
      data.stopInfo.SpeakerVolume.toString()
    );
    data.connectionSuccess = true;
    return data;
  } catch (error) {
    console.warn("Unable to get realtime data:", error);
    return {
      connectionSuccess: false,
      stopInfo: null,
      stopDepartures: null,
      highestPriorityAlert: null,
      isSuperStop: null
    };
  }
}
