import "./styles/index.scss";
import * as arrivalsScreen from "./arrivals";
import * as alertsScreen from "./alerts";
import {
  updateVolumeLabel,
  handleFileUpload,
  setupBroadcastMsgCharacterCounter,
} from "./edit-stop";
import moment from "moment";
import Swiper from "swiper";
import * as audio from "./audio";
import "swiper/dist/css/swiper.css";
import queryString from "query-string";
import fitty from "fitty";
import { setupBroadcastSteps } from "./broadcasts";

const writeLog = (msg: string) =>
  process.stdout ? process.stdout.write(msg) : console.log(msg);

export const ARRIVAL_SCREEN_SWIPER = "arrivalScreenSwiper";

const query = queryString.parse(location.search);
const stopId = query.id as string;

// Refresh everything every 15 seconds
setInterval(() => {
  refreshAll();
}, 15000);

const datetimeElement = document.querySelector<HTMLElement>(
  ".screen-header__datetime"
);

window[ARRIVAL_SCREEN_SWIPER] = new Swiper(".open-screen", {
  autoplay: {
    delay: 10000,
  },
  noSwiping: true,
  allowTouchMove: false,
  speed: 950,
});

const departuresSwiper = new Swiper(".departures-slider", {
  autoplay: {
    delay: 7000,
  },
  observer: true,
  speed: 800,
  noSwiping: true,
  allowTouchMove: false,
  direction: "vertical",
  autoHeight: true,
  slidesPerView: 2,
  slidesPerGroup: 2,
  spaceBetween: 20,
});

// clears the interval on before change to alerts slide
// so that the slide up animation doesn't run while the slide is changing
// This is where you know what we're doing!
window[ARRIVAL_SCREEN_SWIPER].on("slideChangeTransitionStart", function () {
  if (!window[ARRIVAL_SCREEN_SWIPER]) return;

  const alertIndex = window[ARRIVAL_SCREEN_SWIPER].activeIndex;

  // ALERTS ARE DISPLAYING
  if (alertIndex === 1) {
    departuresSwiper.autoplay.stop();
    departuresSwiper.slideTo(0);
    playAlertsScreenAnimation();
    // DEPARTURES ARE DISPLAYING
  } else {
    departuresSwiper.autoplay.start();
    playArrivalsScreenAnimation();
  }
});

async function registerServiceWorker() {
  if ("serviceWorker" in navigator) {
    try {
      const registration = await navigator.serviceWorker.register("/sw.js");

      writeLog(
        `ServiceWorker registration successful with scope: ${registration.scope}`
      );
    } catch (error) {
      console.error("ServiceWorker registration failed: ", error);
    }
  }
}

async function refreshAll() {
  if (!stopId) return;
  try {
    await Promise.all([
      arrivalsScreen.refresh(stopId),
      alertsScreen.refresh(stopId),
    ]);
  } catch (error) {
    console.error(error);
  }
}

async function refreshStatic() {
  return fetch(`/stop/${stopId}/CachedRouteData`);
}

function refreshTime() {
  const datetimeString = moment().format("MMMM D, YYYY | h:mma");

  if (datetimeElement.innerText != datetimeString) {
    datetimeElement.innerText = datetimeString;
  }
}

async function onLoad() {
  try {
    writeLog("Loaded");
    await registerServiceWorker();

    refreshTime();
    setInterval(refreshTime, 1000);

    if (document.body.classList.contains("stop-page")) {
      await refreshAll();

      await refreshStatic();
      setInterval(refreshStatic, 60 * 60 * 1000);
    }

    await audio.init();

    document.body.classList.remove("loading");

    // bug with swiper and autoheight slides for vertical slides
    departuresSwiper.update();
    fitty(".alert-notice__text");
    playArrivalsScreenAnimation();

    writeLog("Ready");
  } catch (error) {
    console.error("Load failed", error);
  }
}

if (document.body.classList.contains("edit-stop-page")) {
  updateVolumeLabel();
  handleFileUpload();
  setupBroadcastMsgCharacterCounter(240);
}

if (document.body.classList.contains("broadcast-page")) setupBroadcastSteps();

function playArrivalsScreenAnimation() {
  const screenHeaderElement =
    document.querySelector<HTMLElement>(".screen-header");
  const screenTitleElement = document.querySelector<HTMLElement>(
    ".screen-title--arrivals"
  );
  const departuresSliderElement =
    document.querySelector<HTMLElement>(".departures-slider");
  const alertNoticeElement =
    document.querySelector<HTMLElement>(".alert-notice");
  const wrapperElement = document.querySelector<HTMLElement>(
    ".arrivals-alerts-container"
  );

  const elements = [
    screenHeaderElement,
    screenTitleElement,
    departuresSliderElement,
    alertNoticeElement,
    wrapperElement,
  ];

  elements.forEach((element, index) => {
    const delay = (index + 1) * 350;
    element.classList.remove("fade-in-animation");
    element.setAttribute("style", "opacity: 0");
    setTimeout(() => element.classList.add("fade-in-animation"), delay);
  });
}

function playAlertsScreenAnimation() {
  const screenHeaderElement =
    document.querySelector<HTMLElement>(".screen-header");
  const screenTitleElement = document.querySelector<HTMLElement>(
    ".screen-title--alerts"
  );
  const alertsContainerElement = document.querySelector<HTMLElement>(
    ".alert-card-container"
  );

  const elements = [
    screenHeaderElement,
    screenTitleElement,
    alertsContainerElement,
  ];

  elements.forEach((element, index) => {
    const delay = (index + 1) * 450;
    element.classList.remove("fade-in-animation");
    element.setAttribute("style", "opacity: 0");
    setTimeout(() => element.classList.add("fade-in-animation"), delay);
  });
}

window.onload = onLoad;
