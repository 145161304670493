export const REST_URL_BASE_AVAIL = "http://realtime.indygo.net/InfoPoint/rest";
export const REST_URL_BASE_SWIFTLY_INFO = "https://api.goswift.ly/info/indygo";
export const REST_URL_BASE_SWIFTLY_REALTIME =
  "https://api.goswift.ly/real-time/indygo";

export const GTFS_REFRESH_TIME = 60 * 60 * 24 * 1000; // 24 hours

export const TIMEZONE = "America/New_York"; // ET timezone
export const CLOSED_PERIOD_PADDING_MINUTES = 5;

export const LINE_DESCRIPTIONS = {
  RED_LINE: "Red Line",
  BLUE_LINE: "Blue Line",
  PURPLE_LINE: "Purple Line",
  GENERIC: "Bus",
};

export const RED_LINE_ALERTS = [90, 901, 902];

export const GHOST_BUSES = [1972, 1980];

export const LINE_COLORS = {
  RED_LINE: "D61328",
  PURPLE_LINE: "893EFF",
  BLUE_LINE: "316A8D",
  DEFAULT: "61AE6C",
};

export const STATION_TYPES = {
  CURBSIDE: "Curbside",
  STATION: "Station",
  SUPER: "Super",
};

export const AVAIL_API_ERROR = "availApiError";
export const TOP_THREE_ALERTS = "topThreeAlerts";

// THESE ARE ALL THE CENTER STATIONS FOR THE REDLINE
export const RED_LINE_CENTER_STATION_IDS = [
  70002, 70003, 70004, 70005, 70006, 70007, 70008, 70009, 70010, 70011, 70012,
  70013, 70014, 70015, 70016, 70017, 70018, 70019, 70020, 70021, 70022, 70023,
  70024, 70025, 70026, 70027, 70028, 70029, 70030, 70031, 70032, 70033, 70034,
  70035, 70036, 70037, 70052, 70053,
];

export const PURPLE_LINE_CENTER_STATION_IDS = [];

export const PURPLE_LINE_STOP_IDS = [
  70014, 70015, 70016, 70017, 70018, 70019, 70020, 70021, 70022, 70023, 70024,
  70025, 70026, 70027, 70028, 70029, 70030, 70031, 70032, 70033, 70034, 70035,
  70036, 70037, 70060, 70061, 70062, 70063, 70064, 70065, 70066, 70067, 70068,
  70069, 70070, 70071, 70072, 70073, 70074, 70075, 70076, 70077, 70078, 70079,
  70080, 70081, 70082, 70083, 70084, 70085, 70086, 70087, 70088, 70089, 70090,
  70091, 70092, 70093, 70094, 90004,
];

export const RED_LINE_STOP_IDS = [
  70001, 70002, 70003, 70004, 70005, 70006, 70007, 70008, 70009, 70010, 70011,
  70012, 70013, 70014, 70015, 70016, 70017, 70018, 70019, 70020, 70021, 70022,
  70023, 70024, 70025, 70026, 70027, 70028, 70029, 70030, 70031, 70032, 70033,
  70034, 70035, 70036, 70037, 70038, 70039, 70040, 70041, 70042, 70043, 70044,
  70045, 70046, 70047, 70048, 70049, 70050, 70051, 70052, 70053,
];

export const PURPLE_ONLY_STOPS = PURPLE_LINE_STOP_IDS.filter(
  (stopId) => !RED_LINE_STOP_IDS.includes(stopId),
);

export const SUPER_STOP_IDS = [51347, 51355, 52200];

export const STOP_IDS = Array.from(
  new Set([...RED_LINE_STOP_IDS, ...SUPER_STOP_IDS, ...PURPLE_LINE_STOP_IDS]),
);

export const CLOSED_STATIONS = [
  ...PURPLE_ONLY_STOPS,
];

export const purpleData = {
  70014: "Park",
  70015: "Park",
  70016: "Meridian and 38th",
  70017: "Meridian and 38th",
  70018: "34th",
  70019: "34th",
  70020: "30th/Museum",
  70021: "30th/Museum",
  70022: "Fall Creek and Ivy Tech",
  70023: "Fall Creek and Ivy Tech",
  70024: "22nd",
  70025: "22nd",
  70026: "18th",
  70027: "18th",
  70028: "IU Health",
  70029: "IU Health",
  70030: "14th",
  70031: "14th",
  70032: "9th",
  70033: "9th",
  70034: "Vermont",
  70035: "Vermont",
  70036: "Statehouse",
  70037: "Statehouse",
  70060: "State Fair 38th & Coliseum EB",
  70061: "State Fair 38th & Coliseum WB",
  70062: "38th & Orchard EB",
  70063: "38th & Orchard WB",
  70064: "38th & Keystone Station EB",
  70065: "38th & Keystone Station WB",
  70066: "38th & Meadows Station EB",
  70067: "38th & Meadows Station WB",
  70068: "38th & Sherman Station EB",
  70069: "38th & Sherman Station WB",
  70070: "38th & Arthington Station EB",
  70071: "38th & Arthington Station WB",
  70072: "38th & Emerson Station EB",
  70073: "38th & Emerson Station WB",
  70074: "38th & Layman Station EB",
  70075: "38th & Layman Station WB",
  70076: "38th & Arlington Station EB",
  70077: "38th & Arlington Station WB",
  70078: "38th & Shadeland Station EB",
  70079: "38th & Shadeland Station WB",
  70080: "38th & Richardt Station EB",
  70081: "38th & Richardt Station WB",
  70082: "38th & Franklin Station EB",
  70083: "38th & Franklin Staton WB",
  70084: "38th & Alsace Station EB",
  70085: "38th & Alsace WB",
  70086: "38th & Post Station EB",
  70087: "38th & Post Station WB",
  70088: "Post & 42nd Station NB",
  70089: "Post & 42nd Station SB",
  70090: "Post & Pendleton Pike Station NB",
  70091: "Post & Pendleton Pike Station SB",
  70092: "Post & 56th Station NB",
  70093: "Post & 56th Station SB",
  70094: "Wheeler & Rising Station EOL",
  90004: "Transit Center",
};

export const TRIGGERED_ANNOUNCEMENTS = [
  {
    label: "See something...",
    name: "120-see-something",
  },
  {
    label: "Take advantage of fare capping",
    name: "05-pay_fare-ctc_rl_stations",
  },
  {
    label: "Please keep your personal belongings with you at all times",
    name: "00-personal-belongings",
  },
  {
    label:
      "In the event of an emergency, please follow the directions of your operator",
    name: "00-in-the-event-of-an-emergency",
  },
  {
    label:
      "For the comfort of all riders, enjoy your music and phone calls at low volume and please use headphones",
    name: "00-use-headphones",
  },
  {
    label: "Due to a special event this station is closed",
    name: "00-special-event-closed",
  },
  {
    label:
      "IndyGo and local law enforcement reserve the right to ask you to leave this station if you are not adhering to IndyGo policies",
    name: "180-indygo-policies",
  },
  {
    label: "Mind the gap between the platform and the vehicle",
    name: "00-mind-the-gap",
  },
  {
    label: "Please help keep our facilities clean",
    name: "00-keep-facilities-clean",
  },
  {
    label: "This station is closed",
    name: "00-closed",
  },
];
