import moment, { Moment } from "moment";
import _ from "lodash";

const writeLog = (msg: string) =>
  process.stdout ? process.stdout.write(msg) : console.log(msg);

// We do not want audio at night when there is no service
const isActiveHour = () => {
  const dayIndex = moment().isoWeekday(); // 1-7 where 1 is Monday and 7 is Sunday
  const hourIndex = new Date().getHours(); // 0-23

  switch(dayIndex) {
    case 6:
      // saturday - inactive between 1am and 6am
      return !(hourIndex >= 1 && hourIndex <= 6);
    case 7:
      // sunday - inactive between 10pm and 7am
      return !(hourIndex >= 22 && hourIndex <= 7);
    default:
      // weekday - inactive between 1am and 5am
      return !(hourIndex >= 1 && hourIndex <= 5);
  }
}

/**
 * The smallest interval is 5 minutes, so each interval is a multiple of 5 minutes
 */
export enum Interval {
  TwelvePerHour = 1,
  SixPerHour = 2,
  FourPerHour = 3,
  TwicePerHour = 6,
  OncePerHour = 12,
  EveryOtherHour = 24,
  EveryThreeHours = 36,
}

export const MAX_INTERVAL = Interval.EveryThreeHours;
export const INTERVAL_DURATION = 650000;

interface ScheduledAnouncement {
  name: string;
  interval: Interval;
  isActive?: () => boolean;
}

export const SCHEDULED_ANOUNCEMENTS: ScheduledAnouncement[] = [
  { name: "05-pay_fare-ctc_rl_stations", interval: Interval.FourPerHour },
  { name: "30-smoke-free", interval: Interval.TwicePerHour },
  {
    name: "30-yellow-safety-strip",
    interval: Interval.TwicePerHour,
  },
  {
    name: "60-designated-door-locations",
    interval: Interval.OncePerHour,
  },
  {
    name: "60-daylight-saving-begins",
    interval: Interval.OncePerHour,
    isActive: () => withinTwoWeeks(moment(), daylightSavingStartDate()),
  },
  {
    name: "60-daylight-saving-ends",
    interval: Interval.OncePerHour,
    isActive: () => withinTwoWeeks(moment(), daylightSavingEndDate()),
  },
  {
    name: "120-see-something",
    interval: Interval.EveryOtherHour,
  },
  {
    name: "120-online",
    interval: Interval.EveryOtherHour,
  },
  {
    name: "120-designated-ramps",
    interval: Interval.EveryOtherHour,
  },
  {
    name: "180-indygo-policies",
    interval: Interval.EveryThreeHours,
  },
];

const ROUTE_ANNOUNCEMENTS = {
  "66th": "next-1min-66th-station",
  "County Line": "next-1min-county-line-road",
  University: "next-1min-university-station",
};

let currentlyPlaying = false;
let audioQueue = [];
let isClosed = false;
let speakerVolume = 1;
let isRedLine = false;

export function daylightSavingStartDate(year: number = null) {
  let date = moment({
    year,
    month: 2,
    hour: 2,
    minute: 0,
    second: 0,
    millisecond: 0,
  });
  return nthWeekday(date, 0, 1);
}

export function daylightSavingEndDate(year: number = null) {
  let date = moment({
    year,
    month: 10,
    hour: 2,
    minute: 0,
    second: 0,
    millisecond: 0,
  });
  return nthWeekday(date, 0, 0);
}

export function withinTwoWeeks(now: Moment, date: Moment) {
  return (
    now.isSameOrAfter(moment(date).subtract(2, "weeks")) &&
    now.isSameOrBefore(date)
  );
}

/**
 * Take a list of scheduled announcements (audio file name and interval) and
 * turn it into a repeating array of audio files to play along with an
 * interval to use with setInterval.
 */
export function scheduleAnnouncements(
  announcements: ScheduledAnouncement[]
): [string[], number] {
  const sortedAnnouncements = _.orderBy(
    announcements,
    [(announcement) => announcement.interval],
    ["desc"]
  );
  const fiveMinutes = _.times(MAX_INTERVAL * 2, () => []);

  for (const { name, interval, isActive } of sortedAnnouncements) {
    if (!isActiveHour()) {
      continue;
    }
    if (isActive && !isActive()) {
      continue;
    }
    // A 5-min interval can only start every 5 minutes (because it repeats every 5 minutes)
    // A one hour interval could start in the first or second half hour (because it repeats 2 half hours)
    // A two hour interval could start in the first, second, third or forth half hour
    // ... and so on
    const possibleStarts = _.times(interval, (index) => index);

    // Find the most empty interval to start repeating the announcement,
    // so the announcements are evened out
    const mostEmptyInterval = _.minBy(
      possibleStarts,
      (index) => fiveMinutes[index].length
    );

    // Add the announcement to the appropriate interval,
    // separated by the specified interval
    for (
      let index = mostEmptyInterval;
      index < fiveMinutes.length;
      index += interval
    ) {
      fiveMinutes[index].push(name);
    }
  }

  const schedule = _.flatten(fiveMinutes);
  const totalMilliseconds = fiveMinutes.length * INTERVAL_DURATION;
  const interval = schedule.length ? (totalMilliseconds / schedule.length) : 2000;

  return [schedule, interval];
}

export async function init() {
  if (document.body.classList.contains("audio-page")) {
    initAudioTriggersPage();
  } else if (document.body.classList.contains("stop-page")) {
    // NOTE - commenting this out in case we ever want to have station id/type specific audios pushed in the future
    const urlSearchParams = new URLSearchParams(window.location.search);
    const { id } = Object.fromEntries(urlSearchParams.entries());
    initStopPage();
  }
}

function initAudioTriggersPage() {
  (<any>window).playAnnouncement = function (
    element: HTMLElement,
    name: string
  ) {
    element.classList.add("active");
    const audio = new Audio(`/audio/${name}.wav`);
    audio.volume =
      parseInt(localStorage.getItem("SpeakerVolume") || "100") / 100;
    audio.addEventListener("ended", function () {
      element.classList.remove("active");
    });
    audio.play();
  };
}

function initStopPage() {
  let [announcements, interval] = scheduleAnnouncements(SCHEDULED_ANOUNCEMENTS);

  writeLog(
    `Scheduled announcements will play every ${Math.round(
      interval / 60000
    )} minutes`
  );
  writeLog(`Three hour schedule: ${announcements}`);

  let index = 0;

  playAudio(announcements[index]);

  setInterval(() => {
    index = index + 1;

    if (index >= announcements.length) {
      [announcements, interval] = scheduleAnnouncements(SCHEDULED_ANOUNCEMENTS);
      index = 0;
    }

    playAudio(announcements[index]);
  }, interval || 2000);
}

/**
 * RedLine is to not play audio between 1a and 5a
 */
function redLineIgnoreAudioHours() {
  const now = new Date().getHours()

  return (now >= 1 && now <= 5);
}

function playAudio(name: string) {
  if (isClosed) {
    return;
  }

  if (isRedLine && redLineIgnoreAudioHours()) {
    return;
  }

  if (currentlyPlaying) {
    // If we're in the middle of playing an audio file, queue up the
    // requested file to play next
    audioQueue.push(name);
  } else {
    writeLog(`Currently Playing:  ${name}`);

    currentlyPlaying = true;

    const audio = new Audio(`/audio/${name}.wav`);
    audio.volume = speakerVolume / 100;

    audio.addEventListener("ended", function () {
      // Always wait at least 10 seconds between audio files
      setTimeout(() => {
        // Only say we're done after the 10 second interval is completed
        currentlyPlaying = false;

        // Play the next queued audio file, if there are any
        if (audioQueue.length > 0) {
          playAudio(audioQueue.splice(0, 1)[0]);
        }
      }, 10 * 1000);
    });

    audio.play();
  }
}

const recentlyPlayedBusNumbers = new Set<string>();

export async function busArriving(
  busNumber: string,
  displayText: string,
  isNorthbound: boolean
) {
  if (recentlyPlayedBusNumbers.has(busNumber)) {
    return;
  }

  recentlyPlayedBusNumbers.add(busNumber);

  let foundAudio = false;

  // Special case handling: if the bus is displaying "transit center",
  // play the 91st street audio for buses going north, and the 66th
  // audio for buses going south
  if (displayText.includes("Transit Center")) {
    if (isNorthbound) {
      displayText = "91st";
    } else {
      displayText = "66th";
    }
  }

  for (const [includesText, announcementName] of Object.entries(
    ROUTE_ANNOUNCEMENTS
  )) {
    if (displayText.includes(includesText)) {
      playAudio(announcementName);
      foundAudio = true;
      break;
    }
  }

  if (!foundAudio) {
    console.warn(`No audio file found for ${displayText}`);
  }

  // Wait at least 2 minutes before playing the same announcement again
  setTimeout(() => {
    recentlyPlayedBusNumbers.delete(busNumber);
  }, 2 * 60 * 1000);
}

export function nthWeekday(date: Moment, weekday: number, nth: number) {
  let month = date.get("month");
  date.set("date", 1);
  date.set("weekday", weekday);

  if (date.get("month") < month) {
    date.add(1, "week");
  }

  date.add(nth, "weeks");

  return date;
}

export function setIsRedLine(redline: boolean) {
  isRedLine = redline;
}

export function setClosed(closed: boolean) {
  isClosed = closed;
}

export function setVolume(volume: number) {
  speakerVolume = volume;
}
